"use client";

import { useTranslations } from "next-intl";

import { useCountdown } from "@hooks/use-countdown";

const Countdown = (): JSX.Element => {
  const t = useTranslations("common.countdown");
  const [days, hours, minutes, seconds] = useCountdown(new Date("2025/3/1"));

  return (
    <div className="flex w-full items-start justify-center gap-2">
      <div className="flex min-w-10 flex-col gap-0.5">
        <h3
          className="text-center text-2xl font-bold leading-9"
          suppressHydrationWarning
        >
          {days}
        </h3>
        <p className="w-full text-center text-xs font-normal uppercase leading-normal text-light-foreground">
          {t("days")}
        </p>
      </div>
      <h3 className="w-3 text-center text-2xl font-medium leading-9 text-light-foreground">
        {":"}
      </h3>
      <div className="flex min-w-10 flex-col gap-0.5">
        <h3
          className="text-center text-2xl font-bold leading-9"
          suppressHydrationWarning
        >
          {hours.toString().padStart(2, "0")}
        </h3>
        <p className="w-full text-center text-xs font-normal uppercase leading-normal text-light-foreground">
          {t("hours")}
        </p>
      </div>
      <h3 className="w-3 text-center text-2xl font-medium leading-9 text-light-foreground">
        {":"}
      </h3>
      <div className="flex min-w-10 flex-col gap-0.5">
        <h3
          className="text-center text-2xl font-bold leading-9"
          suppressHydrationWarning
        >
          {minutes.toString().padStart(2, "0")}
        </h3>
        <p className="w-full text-center text-xs font-normal uppercase leading-normal text-light-foreground">
          {t("minutes")}
        </p>
      </div>
      <h3 className="w-3 text-center text-2xl font-medium leading-9 text-light-foreground">
        {":"}
      </h3>
      <div className="flex min-w-10 flex-col gap-0.5">
        <h3
          className="text-center text-2xl font-bold leading-9"
          suppressHydrationWarning
        >
          {seconds.toString().padStart(2, "0")}
        </h3>
        <p className="w-full text-center text-xs font-normal uppercase leading-normal text-light-foreground">
          {t("seconds")}
        </p>
      </div>
    </div>
  );
};

export default Countdown;
