"use client";

import { useLocale as useLocaleNextIntl } from "next-intl";

import { Locale } from "@types";

export const useLocale = (): Locale => {
  const locale = useLocaleNextIntl() as Locale;

  return locale;
};
